import { DefaultTheme } from 'styled-components'

export const THEME: DefaultTheme = {
  colors: {
    primary: '#1fa1e3',
    base50: '#fff',
    base100: '#809fb8',
    base200: '#97B8C8',
    base300: '#97B8C821',
    base400: '#CCCCCC',
    base700: '#1B1D21',
    base800: '#06152B',
  },
  fonts: {
    primary: '"Montserrat", sans-serif',
    second: '"Rajdhani", sans-serif',
  },
  fontSizes: {
    small: '0.5rem',
    regular: '1rem',
    medium: '2em',
    large: '3em',
  },
  boxShadows: {
    small: '0px 3px 6px #00000047',
  },
  borderRadius: {
    thin: '2px',
    small: '8px',
  },
}
